<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>Tipos de deficiência</h1>
      </div>
      <div class="col">
        <router-link to="deficiencias/cadastro">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :data="deficiencias"
          :columns="columns"
          :itemsPerPage="10">
          <template #column="{item, column}">
            <span v-if="column.field == 'actions'">
              <i class="fas fa-edit mr-3 btn btn-warning" title="Editar deficiência" @click="editarItem(item.id)"></i>
              <i class="fas fa-trash-alt mr-3 btn btn-danger" title="Deletar deficiência" @click="deletarItem(item)"></i>
            </span>
            <span v-else>
              {{item[column.field]}}
            </span>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from './../../../uicomponents/MyTable'
import deficienciasRepository from './../../../services/api/deficienciasRepository'
export default {
  name: 'Empresas',
  components: {
    MyTable
  },
  data(){
    return {
      isLoading: false,
      columns: [
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      deficiencias: []
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    editarItem (id) {
      this.$router.push({ name: 'DeficienciasCadastro', query: { id: id } })
    },
    deletarItem (deficiencia) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover deficiência',
        text: `Deseja deletar a ${deficiencia.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarDeficiencia(deficiencia)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro removido com sucesso!'
          }).then(() => {
            _this.fetch()
          })
          
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarDeficiencia (deficiencia) {
      this.isLoading = true
      deficienciasRepository.deletarDeficiencia(deficiencia.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    fetch () {
      this.isLoading = true
      deficienciasRepository.listarDeficiencias().then(response => {
        this.deficiencias = response.data['data']
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>